export const MainConfig = {
    AppDetails: {
        app_name: "Cepte Kurye", 
        app_description: "Best delivery service app",
        app_identifier: "tr.com.ceptekurye.app",
        ios_app_version: "0.0.1", 
        android_app_version: 100
    },
    FirebaseConfig: {
        apiKey: "AIzaSyDsggR9rpIA2QNWsAn8OWEo9Jy3-iiauA0",
        authDomain: "ecsexpress.firebaseapp.com",
        databaseURL: "https://ecsexpress-default-rtdb.firebaseio.com",
        projectId: "ecsexpress",
        storageBucket: "ecsexpress.appspot.com",
        messagingSenderId: "590297003075",
        appId: "1:590297003075:web:bf87d0e43cc04bc88650f6",
        measurementId: "G-H9TPYY7YL2"
    },
    Google_Map_API_Key: {
        ios: "AIzaSyAnSVXg9YGpHGzccBiPDFbxU7U00u5tQi4",
        android: "AIzaSyAnSVXg9YGpHGzccBiPDFbxU7U00u5tQi4",
        web: "AIzaSyAnSVXg9YGpHGzccBiPDFbxU7U00u5tQi4"
    },
    facebookAppId: "424860145728549",
    PurchaseDetails: {
        CodeCanyon_Purchase_Code: "15aaf15e-082a-4b5a-9696-bca103966cd6",
        Buyer_Email_Address: "mehmet@ecskargo.com.tr"
    }
}